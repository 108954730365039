import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthService } from '@ea/auth';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LocalStorageService } from '@ea/services';

@Component({
  selector: 'ea-reset-email-confirm-page',
  templateUrl: './reset-Email-confirm-page.component.html',
  styleUrls: ['./reset-Email-confirm-page.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, FormsModule],
})
export class ResetEmailCinfirmPageComponent implements OnInit {
  registed = false;
  token: any;
  newemail: any;
  email: any;
  error: boolean = false;

  constructor(
    private auths: AuthService,
    private storage: LocalStorageService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      this.token = paramMap.get('token');
      this.newemail = paramMap.get('new');
    });
    const bearerToken = this.storage.getItem('bearerToken');
    this.auths.getProfile().subscribe((results: any) => {
      this.email = results.emailAddress;
      const payload = {
        email: this.email,
        token: this.token,
        newEmail: this.newemail,
      };
      this.auths.SendEmailChangeConfirm(payload).subscribe((x) => {
        if (x) {
          this.registed = x;
        } else {
        }
      });
    });
  }
}
